import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/campaign",
    beforeEnter: [OnboardingGuard.withCompleteOnboarding],
    children: [
      {
        path: "",
        redirect: { name: "Dashboard" },
      },
      {
        path: "builder/:brandId(\\d+)?/:productId(\\d+)?",
        name: "CampaignBuilder",
        component: () =>
          import("@/modules/campaign/builder/views/CampaignBuilder.vue"),
        meta: {
          title: "modules.campaign.details.meta",
        },
      },
    ],
  },
];
